import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-checkbox-cell',
  template: `
    <input *ngIf="value" type="checkbox" [checked]="isChecked" (change)="onCheckboxChange($event)">
    {{ value }}
  `
})
export class CheckboxCellComponent implements ICellRendererAngularComp {
  @Input() isChecked: boolean = false;
  @Input() value: string;
  @Input() field: string;
  @Input() rowIndex: number;
  @Input() checkboxChange:(checked:boolean, rowIndex:number, field:string, value:string)=>void;

  // ngOnInit(): void {
  //   console.log("received Inputs:", this.value, this.isChecked, this.field, this.rowIndex);
  // }

  agInit(params: any): void {
    if (!params) {
      console.error("params.data is undefined", params);
      return;
    }
     this.value = params.value ? params.value : '';
     this.isChecked = params.isChecked ? true : false;
     this.field = params.colDef.field;
     this.rowIndex = params.rowIndex;
     this.checkboxChange = params.checkboxChange
  }
  refresh(params: any): boolean {
    this.value = params?.value;
    this.isChecked = params?.data[params.colDef.field];
    return true;
  }

  // Handle checkbox change event
  onCheckboxChange(event: any): void {
    // console.log("onCheckboxChange event:", event, "rowIndex:", this.rowIndex, "field:", this.field, this.value)
    const checked = event.target.checked;
    this.checkboxChange(checked, this.rowIndex, this.field, this.value );

  }
}
