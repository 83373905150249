// // The file contents for the current environment will overwrite these during build.
// // The build system defaults to the dev environment which uses `environment.ts`, but if you do
// // `ng build --env=prod` then `environment.prod.ts` will be used instead.
// // The list of which env maps to which file can be found in `.angular-cli.json`.

declare const require: any;

export class environment {
    public static production = true;
    // All of the flags in production has to be false
    public static nonproduction = {
        local: false, // To work with Sam Local
        develop: true, // To work with develop in AWS
        staging: false // To work with staging in AWS
    };
    public static cognito_idp_endpoint = '';
    public static userPoolId = 'us-east-1_pSKT7XECa';
    public static clientId = '4hmpt7k2j2nrvacg4t6nbpe73a';
    public static cognitoRegion = 'us-east-1';
    public static apiURL = 'https://api.trusitylearning.com/user/';
    public static adminURL = "https://api.trusity.com/admin/"
     // added by Kunal bansal here on 14 july 2022 start
     public static getstreamapikey='v9n2xzsujzgf';
     // .....end,,,,,
    public static version = require('../../package.json').version;
    public static awsAccessKeyId = 'AKIAR2XECWZEDPBJBIXV';
    public static awsSecretAccessKey = '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';
    public static awsRegion = environment.cognitoRegion;
    public static awsS3Bucket = 'course-resources-prod';
    public static userpoolPasswordPolicy = {
        minLength: 6,
        maxLength: 16,
        hasNumber: false,
        hasUpperCase: false,
        hasLowerCase: false,
        hasSpecialCharacter: false,
    };
    public static stripePublicKey = "pk_live_51Ii20WHcw6Gfbzm66n75yyddSqwj7T2ls3sIvSNE27O6ZxjJyc8b5fYWIKSM536V21TrmOqPkh5zYGEcHa2G6V7p00EAa9xa2G"
    // public static maximumTierAllowed = 10
    // public static showKioskUser = true
}

