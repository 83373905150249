import { Injectable , Inject} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentListService {
  baseUrl = environment.apiURL;  
  constructor(private http: HttpClient) { }

  getStudents(data): Observable<any> {
    console.log("in getStudents", data);
    const headers = {'content-type': 'application/json'}  
    const body = JSON.stringify(data);
    return this.http.post(this.baseUrl + 'getStudents', body,{'headers':headers})
  }

  getChat(data): Observable<any> {
    const headers = {'content-type': 'application/json'}  
    const body = JSON.stringify(data);
    return this.http.post(this.baseUrl + 'getChat', body,{'headers':headers})
  }
  saveChat(data): Observable<any> {
    const headers = {'content-type': 'application/json'}  
    const body = JSON.stringify(data);
    return this.http.post(this.baseUrl + 'saveMessage', body,{'headers':headers})
  }
  getMedia(data): Observable<any> {
    const headers = {'content-type': 'application/json'}  
    const body = JSON.stringify(data);
    return this.http.post(this.baseUrl + 'getMedia', body,{'headers':headers})
  }
  getNotification(data): Observable<any> {
    const headers = {'content-type': 'application/json'}  
    const body = JSON.stringify(data);
    return this.http.post(this.baseUrl + 'getNotification', body,{'headers':headers})
  }
  getUtilizeNotification(data): Observable<any> {
    const headers = {'content-type': 'application/json'}  
    const body = JSON.stringify(data);
    return this.http.post(this.baseUrl + 'utalizeNotification', body,{'headers':headers})
  }
}
