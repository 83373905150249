import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

// import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeLatestComponent } from './home-latest/home-latest.component';
// import { DemoComponent } from './demo/demo-form/demo-form.component';
import { EnquiryProfessionalComponent } from './enquiry-forms/enquiry-professional/enquiry-professional.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeProfessionalComponent } from './home-professional/home-professional.component';
import { StreamIoComponent } from './streamiotesting/streamio.component';
 // manual merging added on 21 july 2022 start
import { ViewSessionComponent } from './view-session/view-session.component';
import { HomeIndiaComponent } from './home-india/home-india.component';
// import { DemoRescheduleComponent} from './demo/demo-reschedule/demo-reschedule.component';
import { ExternalRedirectComponent } from './external-redirect/external-redirect.component';
import { AuthGuard } from './auth.guard';


const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full', data: {preload: true} },
  // { path: 'home', component: HomeLatestComponent, data: {preload: true}},
  // { path: 'learning', component: HomeProfessionalComponent, data: {preload: true} },

  { path: '', redirectTo: '/trusity', pathMatch: 'full' },
  { path: 'home', redirectTo: '/trusity', pathMatch: 'full' },
  { path: 'learning', redirectTo: '/trusity-learning', pathMatch: 'full' },
  { 
    path: 'trusity', 
    component: ExternalRedirectComponent, 
    data: { externalUrl: 'https://www.trusity.com' },
  },
  { 
    path: 'trusity-learning', 
    component: ExternalRedirectComponent, 
    data: { externalUrl: 'https://www.trusity.com/learning' },
  },
  
  // { path: 'learning', component: HomeProfessionalComponent, data: {preload: true}},
  { path: 'home-india', component: HomeIndiaComponent},
  // { path: 'learning', component: HomeProfessionalComponent, data: {preload: true}},
  // .................added by Kunal bansal on 15 july 2022 start...............
  { path: 'teachers', component: StreamIoComponent , data: {preload: true}, canActivate:[AuthGuard]},
  { path: 'students', component: StreamIoComponent, data: {preload: true}, canActivate:[AuthGuard]},
  // ...................end...................
  // { path: '', loadChildren: () => 
  //   import('./home-latest/home-latest.module').then(m => m.HomeLatestModule), data: {preload: true} },
  { path: '', loadChildren: () => 
    import('./test/test.module').then(m => m.TestModule) },
  // { path: 'home', loadChildren: () => 
  //   import('./home-all/home-all.module').then(m => m.HomeAllModule) },
  { path: '', loadChildren: () => 
    import('./pages/pages.module').then(m => m.PagesModule) },
  { path: '', loadChildren: () => 
    import('./coursepage/category/category.module').then(m => m.CategoryModule) },
  { path: '', loadChildren: () => 
    import('./coursepage/course.module').then(m => m.CourseModule) },
  { path: '', loadChildren: () => 
    import('./Dashboard-Main/dashboard.module').then(m => m.DashboardModule) },
    { path: '', loadChildren: () => 
    import('./login-dash/login.module').then(m => m.LoginModule) },
  // { path: '', loadChildren: () => 
  //   import('./blogpage/blog.module').then(m => m.BlogModule) },
  { path: '', loadChildren: () => 
    import('./policy/policy.module').then(m => m.PolicyModule) },
  { path: '', loadChildren: () => 
    import('./enquiry-forms/enquiry.module').then(m => m.EnquiryModule) },
  { path: '', loadChildren: () => 
    import('./faq-main/faq.module').then(m => m.FaqModule) },
  { path: '', loadChildren: () => 
    import('./workshop-camp/workshop.module').then(m => m.WorkshopModule) },
  // { path: 'demo', component: DemoComponent },
  { path: 'enquire-learning', component: EnquiryProfessionalComponent },
  // { path: 'demo-reschedule/:token', component: DemoRescheduleComponent },
  // { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  // manual merging added on 21 july 2022 start
  { path: 'pilot/:id', component: ViewSessionComponent },
  { path: 'payment', loadChildren:()=> import('./payment/payment.module').then(m=>m.PaymentModule)},
  { path: 'book', loadChildren:()=>import('./schedule-class/schedule-class.module').then(m=>m.ScheduleClassModule)},
  // ....end....
  // 404 Page Not Found
  { path: "**", component: PageNotFoundComponent },
  
];


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
