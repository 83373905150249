import { Injectable } from '@angular/core';
import { HttpClient,  } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
    baseUrl = environment.apiURL
    constructor(private http:HttpClient){

    }

    async getNewCourseDetails(courseId):Promise<any>{
        try{
            const course = await this.http.post(this.baseUrl+"getProductDetails", courseId).toPromise();
            return course;
        }catch(err){
            console.error('Error occurred while getting course detail:', err);
            throw err;
        }
    }

    async getTeacherByCategory(filter):Promise<any>{
        try{
            const url = environment.adminURL;
            // console.log("fileter received:", filter, "json format:",`${JSON.stringify({filter})}` )
            const course = await this.http.post(url+"getTeachersByCategories",`${JSON.stringify({filter})}`).toPromise();
            return course;
        }catch(err){
            console.error('Error occurred while fetching teachers list by category:', err);
            throw err;
        }
    }
}