
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class EndDateCalculatorService {
  /**
   * Calculate the end date for a course based on start date, total classes, and weekly schedule.
   *
   * @param startDate - The start date of the course as a Date object.
   * @param weeklySchedule - List of classes with their day and time. Example:
   *                         [{ day: 'Tuesday', time: '15:00' }, { day: 'Friday', time: '16:00' }]
   * @param totalClasses - Total number of classes for the course.
   * @returns The calculated end date in 'DD/MM/YYYY' format.
   */
  calculateEndDate(startDate: Date, weeklySchedule: { day: string; time: string }[], totalClasses: number): string {
    let currentDate = moment(startDate); // Use the Date object directly
    let classesCovered = 0;

    while (classesCovered < totalClasses) {
      for (const { day, time } of weeklySchedule) {
        const targetDate = currentDate.clone().day(this.getDayIndex(day)).hour(moment(time, 'HH:mm').hour()).minute(moment(time, 'HH:mm').minute());

        // If the target date is before or on the current date, move to the next week's occurrence
        if (targetDate.isBefore(currentDate, 'minute')) {
          targetDate.add(1, 'week');
        }

          classesCovered++;
          currentDate = targetDate;
        if (classesCovered >= totalClasses) break; // Stop if all classes are covered
      }
    }

    return currentDate.format('YYYY-MM-DD'); // Return the end date in the desired format
  }

  /**
   * Helper function to map day names to their index (Sunday = 0, Monday = 1, etc.).
   *
   * @param day - Day name (e.g., 'Monday', 'Tuesday').
   * @returns Index of the day.
   */
  private getDayIndex(day: string): number {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days.indexOf(day);
  }
}
