<!-- <app-navbar-dashboard></app-navbar-dashboard> -->
<!-- ........................new chat header created and implemented by Kunal Bansal on 15 July 2022 start -->
<app-chat-header></app-chat-header>
<!-- ................end............ -->
<!-- <app-sidebar></app-sidebar> -->
<ngx-spinner bdColor="rgba(230, 230, 230, 0.4)" size="large" color="#111F43"></ngx-spinner>
<main class="background">
  <!-- ........................new chat sidebar created and implemented by Kunal Bansal on 15 July 2022 start -->
  <app-chat-sidebar></app-chat-sidebar>
  <!-- ......end.... -->
  <div class="chattestingzone">
    <div class="window-wrap">
      <div class="left-bar">
        
        <div *ngIf="checkurl==0">
          <h3 class="align-items">Teachers List</h3>
          <!-- Replace <ngb-tabset> with <ul ngbNav> -->
          <ul ngbNav #teacherTabset="ngbNav" class="custom-tab-content flex-column flex-md-row" [activeId]="activeId" role="tablist">
            <li ngbNavItem id="tab1">
              <a ngbNavLink><i class="fa fa-comments-o mr-2"></i> Chat</a>
              <ng-template ngbNavContent>
                <div class="list" id="chat-wrapper">
                  <stream-channel-list #channelListComponent></stream-channel-list>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem id="tab2">
              <a ngbNavLink><i class="fa fa-plus mr-2"></i> New Chat</a>
              <ng-template ngbNavContent>
                <div class="list">
                  <div *ngFor="let list of teacherList"
                    (click)="changechannel(list.teacherName, list.teacherKey.teacherID, list.teacherKey.timeStamp, list.conversation_id)">
                    <div class="chat-box d-flex mousehover" 
                      [ngStyle]="uniquechannelid == list.conversation_id ? {'border-left': '2px solid #32325d'} : {'border-left': '2px solid #fff'}">
                      <div class="icon">{{list.initial}}</div>
                      <div class="info ml-3">
                        <h4 class="mb-0">{{list.teacherName}}</h4>
                        <p>{{list.className}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="teacherTabset"></div> <!-- Add ngbNavOutlet to display tab content -->
        </div>

        <div *ngIf="checkurl==1">
          <h3 class="align-items">Students List</h3>
          <!-- Repeat similar changes for student tabs -->
          <ul ngbNav #studentTabset="ngbNav" class="custom-tab-content student-list-cont flex-column flex-md-row" [activeId]="activeId" role="tablist">
            <li ngbNavItem id="tab1">
              <a ngbNavLink><i class="fa fa-comments-o mr-2"></i> Chat</a>
              <ng-template ngbNavContent>
                <div class="list" id="chat-wrapper">
                  <stream-channel-list #channelListComponent></stream-channel-list>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem id="tab2">
              <a ngbNavLink><i class="fa fa-plus mr-2"></i> New Chat</a>
              <ng-template ngbNavContent>
                <div class="list">
                  <div *ngFor="let list of studentList"
                    (click)="changechannel(list.studentName, list.studentKey.studentID, list.studentKey.timeStamp, list.conversation_id)">
                    <div class="chat-box d-flex mousehover" 
                      [ngStyle]="uniquechannelid == list.conversation_id ? {'border-left': '2px solid #32325d'} : {'border-left': '2px solid #fff'}">
                      <div class="icon">{{list.initial}}</div>
                      <div class="info ml-3">
                        <h4 class="mb-0">{{list.studentName}}</h4>
                        <p>{{list.className}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="studentTabset"></div> <!-- Add ngbNavOutlet for student tabs -->
        </div>
        
      </div>

      <!-- Right side chat window content -->
      <div class="right-bar d-flex justify-content-center align-items-center" *ngIf="activechatwindow == 0">
        <h4>Click on user to start chat..</h4>
      </div>

      <div class="right-bar" *ngIf="activechatwindow == 1">
        <stream-channel>
          <stream-channel-header></stream-channel-header>
          <stream-message-list></stream-message-list>
          <app-customstreaminput (channelTab)="moveTab($event)" [channelid]="uniquechannelid"></app-customstreaminput>
        </stream-channel>
      </div>
    </div>
  </div>

  <!-- Channel preview template -->
  <ng-template #channelPreviewTemplate let-channel="channel">
    <app-stream-channel-preview [channel]="channel" (channelEvent)="setChannel($event)"></app-stream-channel-preview>
  </ng-template>

</main>
