import { Injectable } from '@angular/core';
// import { ToastrService } from "ngx-toastr";
import { ResponseModel } from '../models/response-model';
import { SelectItem } from '../model/common';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    // private toastr: ToastrService
    ) { }
  getAgGridDefaultSettings(optional?: any): any {
    return {
      sortable: true,
      resizable: true,
      flex: 1,
      minWidth: 100,
      ...optional
    };
  }

  getTempUser() {
    return [{
      "gradeExperienceOther": "",
      "availableDaysOfWeek": ["Tuesday"],
      "teachingHoursOnWeekday": 2,
      "lastName": "Pl",
      "teachingHoursOnWeekend": 2,
      "email": "neha.pp@efew.ef", "domainExpertisedIn": ["Skill Development"],
      "country": "Albania",
      "availableTimings": ["7-8 am"],
      "firstName": "Neha",
      "curriculumExperienceInOther": "",
      "city": "fqwf",
      "languagesExperiencedIn": ["Arabic"],
      "skillDevelopmentCourses": ["Coding"],
      "howDidYouComeToKnowAboutTrusity": "",
      "dob": "02-02-2018",
      "currentOccupationOther": "",
      "gradeExperience": ["Grade 6 to 8"],
      "timeStamp": 1596812476096,
      "teachingBackground": "",
      "curriculumExperienceIn": ["ICSE"],
      "yearsOfExperience": "Less than a year",
      "highestQualification": "Post Graduate",
      "appOrRejStatus": "Approved",
      "appOrRejOn": 1596812474216,
      "contactNo": "34234234234",
      "teacherID": "a3e4caee-9289-45fe-8cce-1c92df772377",
      "address": "fqf", "currentOccupation":
        "Working Professional", "gender": "Female",
      "availableDaysAndTimings": {
        "Thursday": ["6-7 am", "8-9 am", "1-2 pm", "2-3 pm"],
        "Monday": ["10-11 am", "2-3 pm", "9-10 am", "11-12 am"],
        "Wednesday": ["5-6 am", "8-9 am", "11-12 am", "4-5 pm"]
      },
      "domainExpertisedInOther": []
    }, {
      "gradeExperienceOther": "",
      "availableDaysOfWeek": ["Tuesday"],
      "teachingHoursOnWeekday": 2,
      "lastName": "Rao",
      "teachingHoursOnWeekend": 2,
      "email": "neha.pp@efew.ef", "domainExpertisedIn": ["Skill Development"],
      "country": "Albania",
      "availableTimings": ["7-8 am"],
      "firstName": "Raghav",
      "curriculumExperienceInOther": "",
      "city": "fqwf",
      "languagesExperiencedIn": ["Arabic"],
      "skillDevelopmentCourses": ["Coding"],
      "howDidYouComeToKnowAboutTrusity": "",
      "dob": "02-02-2018",
      "currentOccupationOther": "",
      "gradeExperience": ["Grade 6 to 8"],
      "timeStamp": 1596812476096,
      "teachingBackground": "",
      "curriculumExperienceIn": ["ICSE"],
      "yearsOfExperience": "Less than a year",
      "highestQualification": "Post Graduate",
      "appOrRejStatus": "Approved",
      "appOrRejOn": 1596812474216,
      "contactNo": "34234234234",
      "teacherID": "a3e4caee-9289-45fe-8cce-1c92df7723734",
      "address": "fqf", "currentOccupation":
        "Working Professional", "gender": "Female",
      "availableDaysAndTimings": {
        "Monday": ["6-7 am", "8-9 am", "1-2 pm", "2-3 pm"],
        "Tuesday": ["6-7 am", "8-9 am", "9-10 am", "11-12 am"],
        "Wednesday": ["6-7 am", "8-9 am", "9-10 am", "11-12 am"],
        "Thursday": ["6-7 am", "8-9 am", "9-10 am", "11-12 am"],
        "Saturday": ["6-7 am", "8-9 am", "9-10 am", "11-12 am"],
      },
      "domainExpertisedInOther": []
    }]
  }

  getDaysOfWeek(): string[] {
    return ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  }
  getTimesArray(): string[] {
    return [
      "0-1 am", "1-2 am", "2-3 am", "3-4 am", "4-5 am", "5-6 am", "6-7 am", "7-8 am", "8-9 am",
      "9-10 am", "10-11 am", "11-12 am", "12-1 pm", "1-2 pm", "2-3 pm", "3-4 pm", "4-5 pm", "5-6 pm",
      "6-7 pm", "7-8 pm", "8-9 pm", "9-10 pm", "10-11 pm", "11-12 pm"];
  }
  displayCatchMsg(errRes: ResponseModel<any>) {
    if (errRes.status == "D") {
      // this.toastr.error(`${errRes.msg}`, "Alert!");
    }
      
    else {
      // this.toastr.error(`Something went wrong!`, "Alert!");
      if (errRes && errRes.msg)
        console.error(errRes.msg);

    }
  }
  getDominList(): SelectItem[] {

    //this.categoryService.classesCategory.filter()
    return [
      { value: "Curricular", text: 'Curricular' },
      { value: "Skill Development", text: 'Skill Development' },
      { value: "Competitive Exams", text: 'Competitive Exams' },
      { value: "Other", text: 'Other' }
    ];
  }
  getDefaultDropDownSettings(extraParams?: {}) {
    return {
      singleSelection: false,
      idField: 'value',
      textField: 'text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableCheckAll: false,
      itemsShowLimit: 3,
      ...extraParams
    };
  }
  getUniqueSelectItemArr(arr) {
    var newUniqueArr = [];
    if (Array.isArray(arr) && arr.length > 0) {
      var firstVal = arr[0];
      if (typeof firstVal == "object" && firstVal.hasOwnProperty("text") && firstVal.hasOwnProperty("value")) {
        arr.forEach(u => {
          if (newUniqueArr.filter(f => f.value == u.value && f.text == u.text).length == 0) {
            newUniqueArr.push(u);
          }
        });
        return newUniqueArr;
      } else if (typeof firstVal == "string") {
        return arr.filter(this.uniqueFilterArr);
      } else {
        return arr;
      }
    }
    else {
      return arr;
    }
  }
  uniqueFilterArr(value, index, self) {
    return (self.indexOf(value) === index);
  }
  getCurriculumList(): SelectItem[] {
    return [
      { value: "CBSE", text: 'CBSE' },
      { value: "ICSE", text: 'ICSE' },
      { value: "IGCSE", text: 'IGCSE' },
      { value: "IB", text: 'IB' },
      { value: "Other", text: 'Other' }
    ];
  }
  getGradeExperienceList(): SelectItem[] {
    return [
      { value: "Grade 1 to 5", text: 'Grade 1 to 5' },
      { value: "Grade 6 to 8", text: 'Grade 6 to 8' },
      { value: "Grade 9 to 10", text: 'Grade 9 to 10' },
      { value: "Grade 11 to Above", text: 'Grade 11 to Above' },
      { value: "Other", text: 'Other' }
    ];
  }
  getCurrentOccupationList(): SelectItem[] {
    return [
      { value: "Full Time Teacher", text: 'Full Time Teacher' },
      { value: "Working Professional", text: 'Working Professional' },
      { value: "University/College Students", text: 'University/College Students' },
      { value: "Self Employed", text: 'Self Employed' },
      { value: "Other", text: 'Other' }
    ];
  }
  getSkillDevelopmentCoursesList(): SelectItem[] {
    return [
      { value: "Mental Math", text: 'Mental Math' },
      { value: "Coding", text: 'Coding' },
      { value: "Creative Writing", text: 'Creative Writing' },
      { value: "Handwriting", text: 'Handwriting' },
      { value: "Guitar", text: 'Guitar' },
      { value: "Dance Indian Classical", text: 'Dance Indian Classical' },
      { value: "Vocal Indian Classical", text: 'Vocal Indian Classical' },
      { value: "Fittness & Wellbeing", text: 'Fittness & Wellbeing' },
      { value: "Youngpreneurship", text: 'Youngpreneurship' },
      { value: "Leadership & Communication Skills", text: 'Leadership & Communication Skills' },
      { value: "Robotics", text: 'Robotics' },
      { value: "Space", text: 'Space' },
      { value: "Creative Art", text: 'Creative Art' },
      { value: "Calligraphy", text: 'Calligraphy' },
      { value: "Keyboard", text: 'Keyboard' },
      { value: "Dance Bollywood", text: 'Dance Bollywood' },
      { value: "Vocal English", text: 'Vocal English' },
      { value: "Yoga", text: 'Yoga' },
      { value: "Financial Literacy", text: 'Financial Literacy' }
    ];
  }
  getLanguagesExperiencedInList(): SelectItem[] {
    return [
      { value: "English", text: 'English' },
      { value: "Arabic", text: 'Arabic' },
      { value: "French", text: 'French' },
      { value: "German", text: 'German' },
      { value: "Hindi", text: 'Hindi' },
      { value: "Kannada", text: 'Kannada' },
      { value: "Tamil", text: 'Tamil' },
      { value: "Telgu", text: 'Telgu' },
      { value: "Malayalam", text: 'Malayalam' },
      { value: "Marathi", text: 'Marathi' }
    ];
  }
  getAvailableDaysOfWeekList(): SelectItem[] {
    return [
      { value: "Sunday", text: 'Sunday' },
      { value: "Monday", text: 'Monday' },
      { value: "Tuesday", text: 'Tuesday' },
      { value: "Wednesday", text: 'Wednesday' },
      { value: "Thursday", text: 'Thursday' },
      { value: "Friday", text: 'Friday' },
      { value: "Saturday", text: 'Saturday' }
    ];
  }
  getAvailableTimingsList() {
    return [
      // { value: "6-7 am", text: '6-7 am' },
      // { value: "7-8 am", text: '7-8 am' },
      { value: "8-9 am", text: '8-9 am' },
      { value: "9-10 am", text: '9-10 am' },
      { value: "10-11 am", text: '10-11 am' },
      { value: "11-12 am", text: '11-12 am' },
      { value: "12-1 pm", text: '12-1 pm' },
      { value: "1-2 pm", text: '1-2 pm' },
      { value: "2-3 pm", text: '2-3 pm' },
      { value: "3-4 pm", text: '3-4 pm' },
      { value: "4-5 pm", text: '4-5 pm' },
      { value: "5-6 pm", text: '5-6 pm' },
      { value: "6-7 pm", text: '6-7 pm' },
      { value: "7-8 pm", text: '7-8 pm' },
    ];
  }

  getDays(): string[] {

    let days = [];
    for (let i = 1; i <= 31; i++) {
      days.push((i < 10 ? ("0" + i) : i + ""));
    }
    return days;
  }
  getMonths(): SelectItem[] {
    let month = [];
    month.push(new SelectItem("01", "Jan"));
    month.push(new SelectItem("02", "Feb"));
    month.push(new SelectItem("03", "Mar"));
    month.push(new SelectItem("04", "Apr"));
    month.push(new SelectItem("05", "May"));
    month.push(new SelectItem("06", "Jun"));
    month.push(new SelectItem("07", "Jul"));
    month.push(new SelectItem("08", "Aug"));
    month.push(new SelectItem("09", "Sep"));
    month.push(new SelectItem("10", "Oct"));
    month.push(new SelectItem("11", "Nov"));
    month.push(new SelectItem("12", "Dec"));
    return month;
  }
  getYear(): string[] {
    let year = [];
    let dt = new Date();
    let thisYear = dt.getFullYear();
    let startYear = thisYear - 100;
    for (let i = thisYear; i >= startYear; i--) {
      year.push(i + "");
    }
    return year;
  }
  getValueFormSelectItem(item): string {
    if (typeof item == "object" && item.hasOwnProperty("value"))
      return item.value;
    else
      return item;
  }
  getTextFormSelectItem(item): string {
    if (typeof item == "object" && item.hasOwnProperty("text"))
      return item.text;
    else
      return item;
  }
  convertToYYYY_MM_DD(val) {
    var dateArr = val.split("/");
    let day = dateArr[0];
    let month = dateArr[1]
    let year = dateArr[2];
    return `${year}-${month}-${day}`
  }
  convertToDDSMMSYYYY(val) {
    var dateArr = val.split("-");
    let day = dateArr[2];
    let month = dateArr[1]
    let year = dateArr[0];
    return `${day}/${month}/${year}`
  }

  getCountries(): string[] {
    return ['United States',
      'Afghanistan',
      'Albania',
      'Algeria',
      'American Samoa',
      'Andorra',
      'Angola',
      'Anguilla',
      'Antigua and Barbuda',
      'Argentina',
      'Armenia',
      'Aruba',
      'Australia',
      'Austria',
      'Azerbaijan',
      'The Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bermuda',
      'Bhutan',
      'Bolivia',
      'Bosnia and Herzegovina',
      'Botswana',
      'Brazil',
      'Brunei',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cambodia',
      'Cameroon',
      'Canada',
      'Cape Verde',
      'Cayman Islands',
      'Central African Republic',
      'Chad',
      'Chile',
      'China',
      'Christmas Island',
      'Cocos (Keeling) Islands',
      'Colombia',
      'Comoros',
      'Congo',
      'Cook Islands',
      'Costa Rica',
      'Cote d\'Ivoire',
      'Croatia',
      'Cuba',
      'Curacao',
      'Cyprus',
      'Czech Republic',
      'Democratic Republic of the Congo',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Ethiopia',
      'Falkland Islands',
      'Faroe Islands',
      'Fiji',
      'Finland',
      'France',
      'French Polynesia',
      'Gabon',
      'The Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Gibraltar',
      'Greece',
      'Greenland',
      'Grenada',
      'Guadeloupe',
      'Guam',
      'Guatemala',
      'Guernsey',
      'Guinea',
      'Guinea-Bissau',
      'Guyana',
      'Haiti',
      'Honduras',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran',
      'Iraq',
      'Ireland',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jersey',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      'North Korea',
      'South Korea',
      'Kosovo',
      'Kuwait',
      'Kyrgyzstan',
      'Laos',
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macau',
      'Macedonia',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Martinique',
      'Mauritania',
      'Mauritius',
      'Mayotte',
      'Mexico',
      'Micronesia',
      'Moldova',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Montserrat',
      'Morocco',
      'Mozambique',
      'Myanmar',
      'Nagorno-Karabakh',
      'Namibia',
      'Nauru',
      'Nepal',
      'Netherlands',
      'Netherlands Antilles',
      'New Caledonia',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Niue',
      'Norfolk Island',
      'Turkish Republic of Northern Cyprus',
      'Northern Mariana',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau',
      'Palestine',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Philippines',
      'Pitcairn Islands',
      'Poland',
      'Portugal',
      'Puerto Rico',
      'Qatar',
      'Republic of the Congo',
      'Romania',
      'Russia',
      'Rwanda',
      'Saint Barthelemy',
      'Saint Helena',
      'Saint Kitts and Nevis',
      'Saint Lucia',
      'Saint Martin',
      'Saint Pierre and Miquelon',
      'Saint Vincent and the Grenadines',
      'Samoa',
      'San Marino',
      'Sao Tome and Principe',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'Somaliland',
      'South Africa',
      'South Ossetia',
      'South Sudan',
      'Spain',
      'Sri Lanka',
      'Sudan',
      'Suriname',
      'Svalbard',
      'eSwatini',
      'Sweden',
      'Switzerland',
      'Syria',
      'Taiwan',
      'Tajikistan',
      'Tanzania',
      'Thailand',
      'Timor-Leste',
      'Togo',
      'Tokelau',
      'Tonga',
      'Transnistria Pridnestrovie',
      'Trinidad and Tobago',
      'Tristan da Cunha',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Turks and Caicos Islands',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'United Kingdom',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Vatican City',
      'Venezuela',
      'Vietnam',
      'British Virgin Islands',
      'Isle of Man',
      'US Virgin Islands',
      'Wallis and Futuna',
      'Western Sahara',
      'Yemen',
      'Zambia',
      'Zimbabwe',
      'other'];;
  }

}
