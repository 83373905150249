import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StreamChat } from 'stream-chat';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { UserLoginService } from '../services/user.service';
import { PushNotificationService } from '../services/pushNotificationService';
@Component({
  selector: 'app-customstreaminput',
  templateUrl: './customstreaminput.component.html',
  styleUrls: ['./customstreaminput.component.scss']
})

// ..........stream chat custom component added by Kunal bansal for creating custom input on 20 july 2022 start.............
export class CustomStreamInputComponent implements OnInit {
  @Input() channelid: string = '';
  @Output() channelTab = new EventEmitter<boolean>();
  uploadfile: any;
  message: string = '';
  uploadfiletype: string = '';
  streamdbuploadedfilelink: string = ''

  constructor(private spinner: NgxSpinnerService, 
    private userLoginService:UserLoginService,
    private notificationService:PushNotificationService
  ) {
  }

  ngOnInit() {

  }

  async fileupload(event: any) {
    this.uploadfile = event.target.files[0];
    this.uploadfiletype = this.uploadfile.type;
    let filesize = this.uploadfile.size / 1024 / 1024;
    console.log(this.uploadfile)
    if (filesize > 100) {
      alert('you cannot upload file greater then 5mb')
      event.target.value = '';
    }
    else if (
      this.uploadfiletype.includes('image') || 
      this.uploadfiletype == 'application/pdf' || 
      this.uploadfiletype == 'application/msword' || 
      this.uploadfiletype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      this.uploadfiletype == 'application/vnd.ms-powerpoint' || 
      this.uploadfiletype == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || 
      this.uploadfiletype == 'application/vnd.ms-excel' || 
      this.uploadfiletype == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || 
      this.uploadfiletype.includes('video')) {
      this.spinner.show();
      const chatClient = StreamChat.getInstance(environment.getstreamapikey);
      const channel = chatClient.channel('messaging', this.channelid);
      await channel.sendFile(this.uploadfile).then((res: any) => {
        console.log(res)
        this.streamdbuploadedfilelink = res.file;
        this.sendfiles();
      }, err => {
        this.spinner.hide();

      });

    }
    else {
      alert('You can only send images, videos or documents here')
      event.target.value = '';
    }
  }

  // ...................stream chat custom functions added by Kunal bansal on 20 july 2022 start................


  //     // ................function for sending messages in channel by kunal bansal on 20 july 2022 start.........
  async sendmessage() {

    const chatClient = StreamChat.getInstance(environment.getstreamapikey);
    const channel2 = chatClient.channel('messaging', this.channelid);
    if (this.message.trim() == '') {
      alert("Please type some message")
    }

    else {

      const message = await channel2.sendMessage({
        text: this.message,
        attachments: [],
      });
      this.channelTab.emit(true);
      this.sendNotificationToUser(0, this.message);
    }
    this.message = '';
  }

  async sendfiles() {
    const chatClient = StreamChat.getInstance(environment.getstreamapikey);
    const channel2 = chatClient.channel('messaging', this.channelid);

    let imagebody = {
      type: 'image',
      fallback: this.uploadfile.name,
      image_url: this.streamdbuploadedfilelink
    }

    let filebody = {
      type: 'file',
      title: this.uploadfile.name,
      file_size: this.uploadfile.size,
      asset_url: this.streamdbuploadedfilelink
    }

    const message = await channel2.sendMessage({
      text: '',
      attachments: [
        this.uploadfiletype.includes('image') ? imagebody : filebody
      ],
    }).then((res: any) => {
      this.spinner.hide();
      this.channelTab.emit(true);
      this.sendNotificationToUser(1, 'file');
    }, err => {
      this.spinner.hide();
      this.channelTab.emit(false);
    });

    // ..........................end......................

  }


  sendNotificationToUser = async (type: Number, message: String) => {
    const chatClient = StreamChat.getInstance(environment.getstreamapikey);
    const channel = chatClient.channel('messaging', this.channelid);

    await channel.watch();
    const members = Object.keys(channel.state.members);
    const userInfo:any = await this.userLoginService.getUserInfo();

		let r_id = ''
		let r_time:any = ''
    for (let memberId of members) {
      if (memberId !== userInfo.key.studentID) {
        r_id = memberId;
        r_time = channel.state.members[memberId]?.user?.timeStamp;
        break;
      }
    }

		let data = {
			id: r_id,//receiver id
			timestamp: r_time,//receiver timestamp
			send_by_username: userInfo.firstName,
			is_media: type, // 0 for text and 1 for file,
			message: message, // "file" for file else message
			channel_id: this.channelid, //channel id to hide notification if already on the same chat window
		};
		console.log("data in notification",data);
    try{
      const res = await this.notificationService.sendNotification(data);  

      console.log("Notification sent successfully:", res);
    }catch(err){
      console.log("Error in sending notification:", err.message);
    }
	};


}
