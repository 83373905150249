import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
// import { ToastrService } from 'ngx-toastr';
import { ResponseModel } from "../model/response-model";
import { CommonService } from "../services/common.service";
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class PushNotificationService{
    baseUrl = environment.apiURL;
  constructor(private httpClient: HttpClient, 
    // rivate toastr: ToastrService, 
    private commonService: CommonService) { }


    async sendNotification(data){
        try{
            const res = await this.httpClient.post(this.baseUrl+"sendChatPushNotification", data).toPromise();
            return res;
        }catch(err){
            console.log("Error in sending notification:", err.message);
            throw err;
        }   
    }
}