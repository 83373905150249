import {  CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookClassComponent } from './book-class/book-class.component';
// import { 
//   MatDatepickerModule, 
//   MatNativeDateModule, 
//   MatFormFieldModule, 
//   MatInputModule,
//   MAT_DATE_LOCALE
// } from "@angular/material";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
// import { BsModalService } from 'ngx-bootstrap';
import { FormsModule } from '@angular/forms';
import { ScheduleRoutingModule } from './schedule-routing.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgGridModule } from 'ag-grid-angular';
import { CheckboxCellComponent } from './checkbox-cell/checkbox-cell.component';



@NgModule({
  declarations: [
    BookClassComponent,
    CheckboxCellComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ScheduleRoutingModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    NgxSpinnerModule,
    AgGridModule.withComponents([BookClassComponent]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports:[
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ]
})
export class ScheduleClassModule { };
