import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CognitoCallback, CognitoUtil, LoggedInCallback } from "./cognito.service";
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CookieStorage } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import { ToastrService } from 'ngx-toastr';
import { Constants } from "./constants";
import { CacheService } from "./cache.service";
import { DataService } from "./data.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs/Observable";
import 'rxjs/add/observable/bindNodeCallback';
import { BsModalService } from "ngx-bootstrap/modal";
import { of } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { UrlConstants } from './../services/url.constants';
import { LoggedInUserInfo } from "../model/LoggedInUserInfo";
import { userInfo } from "os";
import { StreamChat } from "stream-chat";


@Injectable()
export class UserLoginService {

    public rememberMe;
    public userName;
    public profileName;
    private cognitoUser: CognitoUser;
    private cognitoHandlers;

    constructor(
        private activeRoute: ActivatedRoute,
        private cognitoUtil: CognitoUtil,
        private router: Router,
        private toasterService: ToastrService,
        private constants: Constants,
        private cacheService: CacheService,
        private dataService: DataService,
        private spinner: NgxSpinnerService,
        private modalService: BsModalService,
        private http: HttpClient,
        private urlConstants: UrlConstants
    ) {
    }

    authenticate(username: string, password: string, options?: AuthenticateOptions) {
        console.log("UserLoginService: starting the authentication")
        self = this;
        let authenticationData = {
            Username: username,
            Password: password,
        };
        console.log("authenticationData = ", authenticationData)
        let authenticationDetails = new AuthenticationDetails(authenticationData);
        var poolData = {
            UserPoolId: environment.userPoolId,
            ClientId: environment.clientId
        };
        var userPool = new CognitoUserPool(poolData)
        // console.log("userPool = ", userPool)

        let userData = {
            Username: username,
            Pool: userPool
        };

        console.log("UserLoginService: Params set...Authenticating the user");
        this.cognitoUser = new CognitoUser(userData);
        console.log("UserLoginService: config is " + JSON.stringify(AWS.config,null,2));
        var self = this;

        //this.rememberMe = rememberMe;
        this.userName = username;

        this.cognitoHandlers = {

            onSuccess: this.authenticateSuccess.bind(this),

            onFailure: this.authenticateFailed.bind(this),

            newPasswordRequired: this.newPasswordRequired.bind(this, options.newPasswordHandler)
        }

        // call cognito authenticateUser function to authenticate user
        this.cognitoUser.authenticateUser(authenticationDetails, this.cognitoHandlers);
    }

    public newPasswordRequired(newPasswordHandler, userAttributes, requiredAttributes,) {
        newPasswordHandler().subscribe((newPassword) => {
            const body = document.getElementsByTagName('body')[0];
            body.classList.remove('modal-open');
            delete userAttributes.email_verified;
            delete userAttributes.email;
            delete userAttributes.phone_number;
            delete userAttributes.phone_number_verified;
            delete userAttributes["custom:user_type"];
            delete userAttributes["custom:account_id"];
            console.log("Got the change password callback.", newPassword);
            this.cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this.cognitoHandlers);
        });
        console.log(userAttributes, requiredAttributes);
    }

    public authenticateSuccess(result) {
        this.setCognitoUserSessionTokens(result);
        // this.setRememberMe(this.rememberMe);
        this.setUserName(this.userName);
        this.dataService.resetAuthHeader();
        this.modalService.hide(1);
        // this.spinner.hide();
        //this function make it dicide based on Normal-admin/Super-admin and redirect appropriate components
        this.getUserAttributes();
    }


    public authenticateFailed(err) {
        console.log(err);
        if (err.code == this.constants.NOT_AUTH_EXCEPTION && err.message == this.constants.USER_DISABLED) {
            this.toasterService.error(this.constants.TOAST_TYPE_ERROR, this.constants.PLEASE_CONTACT_ADMIN);
        }
        else {
            this.toasterService.error(this.constants.TOAST_TYPE_ERROR, "", err.message);
        }
        this.spinner.hide();
    }

    isAuthenticated() {
        //check if the user exists
        // let cognitoUser = this.getUserName();
        let cognitoUser = this.cognitoUtil.getCurrentUser();
        if (cognitoUser != null) {
            sessionStorage.setItem("userName", cognitoUser.getUsername());
            return true;
        } else {
            console.log("UserLoginService: can't retrieve the current user");
            return false;
        }
    }


    // get logged in cognoto user attributes
    getUserAttributes() {
        let adminuser = 'Student';
        let userData;
        let accountID;
        let emailID;
        this.cognitoUser.getUserAttributes((err, result) => {
            if (err) {
                console.log(err)
            }
            else {
                console.log("attr", result)
                // loop the user attributes, check -(custom:user_type) attribute and hold the value
                for (let i = 0; i < result.length; i++) {
                    if (result[i].getValue() == 'Parent' || result[i].getValue() == 'Student' || result[i].getValue() == 'Teacher') {
                        userData = result[i].getValue();
                    }
                    if (result[i].getName() == "custom:account_id") {
                        accountID = result[i].getValue();
                    }
                    if (result[i].getName() == "email") {
                        emailID = result[i].getValue();
                    }
                }
                
                // this.cacheService.adminAccountId = profileName;
                this.cacheService.appAccountEmail = emailID;
                // console.log("this.cacheService.adminAccountId  = ",this.cacheService.adminAccountId)
            
                // if loggedin as normal-admin - redirect to "event-list"
                if (userData == "Student" || userData == "Parent" || userData == "Teacher") {
                    this.cacheService.appUserType = userData;
                    this.setUserInfo(this.cacheService.appUserType, emailID).then(u => {
                        // added by Kunal bansal here on 14 july 2022 start
                        const chatClient = StreamChat.getInstance(environment.getstreamapikey);
                        chatClient.disconnectUser();
                        // ....end...
                        this.router.navigate(["/dashboard"], { relativeTo: this.activeRoute });
                    }).catch(err => {
                        console.error(err);
                        this.router.navigate(["/dashboard"], { relativeTo: this.activeRoute });
                    })

                }
                // if (userData == "Parent") {
                //     this.cacheService.appUserType = 'Parent';
                //     this.setUserInfo(this.cacheService.appUserType, emailID).then(u => {
                //         return this.router.navigate(["/dashboard"], { relativeTo: this.activeRoute });
                //     }).catch(err => {
                //         console.error(err);
                //         return this.router.navigate(["/dashboard"], { relativeTo: this.activeRoute });
                //     })

                // }

                // if (userData == "Teacher") {
                //     this.cacheService.appUserType = 'Teacher';
                //     this.setUserInfo(this.cacheService.appUserType, emailID).then(u => {
                //         return this.router.navigate(["/dashboard"], { relativeTo: this.activeRoute });
                //     })

                // }
            }
        })

    }
    setUserInfo(userType, userEmail) {
        return new Promise((resolve, reject) => {
            let Params = JSON.stringify({ userType: userType, userEmail: userEmail });
            this.http.post(`${this.urlConstants.getUri(this.urlConstants.getUserInfo)}`, Params).subscribe(
                res => {
                    var data = res["data"];
                    if (data) {
                        localStorage.setItem("userInfo", JSON.stringify(data))
                        // added by Kunal on 15 july 2022 start
                        sessionStorage.setItem("userInfo", JSON.stringify(data))
                        // ....end...
                        this.spinner.hide();
                        resolve(data);
                    } else {
                        let tmpUsrInfo = new LoggedInUserInfo();
                        localStorage.setItem("userInfo", JSON.stringify(tmpUsrInfo))
                         // added by Kunal on 15 july 2022 start
                         sessionStorage.setItem("userInfo",JSON.stringify(tmpUsrInfo))
                         // ....end...
                        this.spinner.hide();
                        // alert("No data found");
                        reject({ message: "LoggedIn UserInfo not found" });
                    }
                },
                error => {
                    console.log(error);
                    this.spinner.hide();
                    reject(error);
                })
        });
    }

    isValidSession() {
        console.log("checking session validity");
        var isAuthenticated = false;
        let cognitoUser = this.cognitoUtil.getCurrentUser();

        if (cognitoUser) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    console.log("could not fetch user session tokens");
                } else {
                    this.setCognitoUserSessionTokens(session);
                    this.dataService.resetAuthHeader();
                    isAuthenticated = true;
                    console.log("user session is valid for this page");
                }
            });
        }
        return isAuthenticated;
    }

    setRememberMe(rememberMe) {
        if (rememberMe) {
            //set remember me value as "1" if selected
            localStorage.setItem("Rem", "1");
        } else {
            //set remember me value as "null" if not selected
            localStorage.setItem("Rem", null);
        }
    }

    getRememberMe() {
        return this.rememberMe = localStorage.getItem("Rem");
    }

    setUserName(userName) {
        this.userName = userName;
        var rememberMe = this.rememberMe;
        if (rememberMe) {
        // set the username in locastorage if remember me is checked while login
        localStorage.setItem("userName", this.userName);
        // localStorage.setItem("Name", this.name);
        } else {
            //set the username in session storage and set it as "null" in localstorage if remember me is not selected
            localStorage.setItem("userName", null);
            sessionStorage.setItem("userName", this.userName);
        }
    }

    getUserName() {
        if (this.getRememberMe() == "1") {
            //get the name from localstorage if remember me was selected while login
            return this.userName = localStorage.getItem("userName");
        } else {
            //get the name from sessionstorage if remember me was selected while login
            return this.userName = sessionStorage.getItem("userName");
        }

        // return this.userName = localStorage.getItem("userName")

    }

    logout() {
        console.log("UserLoginService: Logging out");

        //call cognito function for signout 
        // if (this.cognitoUtil.getCurrentUser().signOut()) {
        //     this.cognitoUtil.getCurrentUser().signOut();
        // }

        // Clean up other stuff
        this.cacheService.remove(this.constants.COGNITO_IDTOKEN);
        this.cacheService.remove(this.constants.COGNITO_ACCESSTOKEN);
        this.cacheService.remove(this.constants.COGNITO_REFRESHTOKEN);
        this.dataService.resetAuthHeader();
        localStorage.clear();
        sessionStorage.clear();
        return this.router.navigate(["/login"], { relativeTo: this.activeRoute });

    }

    setCognitoUserSessionTokens(userSession) {
        this.cacheService.put(this.constants.COGNITO_IDTOKEN, userSession.getIdToken());
        this.cacheService.put(this.constants.COGNITO_ACCESSTOKEN, userSession.getAccessToken());
        this.cacheService.put(this.constants.COGNITO_REFRESHTOKEN, userSession.getRefreshToken());
    }

    public setLocalStorageUserName(newToken) {
        const localIdTokenName = this.localStorageIdToken();
        localStorage.setItem(localIdTokenName, newToken)
        // this.cacheService.put(this.constants.COGNITO_IDTOKEN, newToken);
    }

    public localStorageIdToken() {
        let poolData = {
            UserPoolId: environment.userPoolId, // Your user pool id here
            ClientId: environment.clientId // Your client id here
        };
        let userPool = new CognitoUserPool(poolData);
        let userName;
        let profileName;
        const localUserName = localStorage.getItem("userName")
        if (localUserName !== "null") {
            userName = localUserName;
        } else {
            userName = sessionStorage.getItem("userName");
            // profileName = sessionStorage.getItem("name");
            console.log(profileName)
        }
        const keyPrefix = `CognitoIdentityServiceProvider.${userPool.getClientId()}.${userName}.idToken`;
        return keyPrefix;
    }

    public refreshToken(): any {
        const poolData = {
            UserPoolId: environment.userPoolId,
            ClientId: environment.clientId
        };
        const userPool = new CognitoUserPool(poolData);
        const cognitoUser = userPool.getCurrentUser();

        /* =======================================
        Code of the refreshSession is document on -
        https://github.com/aws/aws-amplify/tree/master/packages/amazon-cognito-identity-js
        Use case 32. Handling expiration of the Id Token.
        ========================================*/
        const oldToken = Observable.bindNodeCallback(cognitoUser.getSession).bind(cognitoUser);
        const newToken = Observable.bindNodeCallback(cognitoUser.refreshSession).bind(cognitoUser);
        return oldToken()
            .flatMap(oldSession => {
                return newToken(oldSession.getRefreshToken());
            }).flatMap(refreshToken => {
                return of(refreshToken.idToken.jwtToken);
            }).catch(err => {
                return Observable.throw(err);
            })
    }

    public forgotpassword(username, options?) {
        var spinner = this.spinner;
        var poolData = {
            UserPoolId: environment.userPoolId, // Your user pool id here
            ClientId: environment.clientId // Your client id here
        };
        var userPool = new CognitoUserPool(poolData)
        var userData = {
            Username: username,
            Pool: userPool
        };
        console.log("userData = ",userData);
        var cognitoUser = new CognitoUser(userData);
        // spinner.show();
        cognitoUser.forgotPassword({
            onSuccess: (data) => {
                spinner.hide();
                console.log("CodeDeliveryData from forgotPassword: " + data);
            },
            onFailure: (err) => {
                spinner.hide();
                if (err) {
                    if (err.name == this.constants.USER_USERNOTFOUNDEXCEPTION) {
                        this.toasterService.error(this.constants.TOAST_TYPE_ERROR, this.constants.USER_NOT_FOUND);
                    } else if (err.name == this.constants.USER_NOTAUTHORIZEDEXCEPTION) {
                        this.toasterService.error(this.constants.TOAST_TYPE_ERROR, this.constants.USER_CONTACT_ADMIN);
                    } else {
                        this.toasterService.error(this.constants.TOAST_TYPE_ERROR,  err.message);
                    }
                }

            },
            inputVerificationCode: (data) => {
                var ROUTERS = this.router;
                var TOASTER = this.toasterService;
                var CONSTANTS = this.constants;
                var MODALSERVICE = this.modalService
                this.toasterService.success(this.constants.TOAST_TYPE_SUCCESS, this.constants.OTP_SENT_SUCCESSFULLY);
                options().subscribe((newPassword) => {
                    var otp = newPassword[0];
                    var pwd = newPassword[1];
                    cognitoUser.confirmPassword(otp, pwd, {
                        onSuccess() {
                            spinner.hide();
                            console.log("Password confirmed!");
                            TOASTER.success(CONSTANTS.TOAST_TYPE_SUCCESS, CONSTANTS.PASSWORD_RESET_SUCCESSFUL);
                            setTimeout(() => { MODALSERVICE.hide(1); }, 1000);
                            setTimeout(() => { ROUTERS.navigate(["/login"]); }, 1000);
                        },
                        onFailure(err) {
                            spinner.hide();
                            console.log("Password not confirmed!");
                            TOASTER.error(CONSTANTS.TOAST_TYPE_ERROR, err.message);
                        }
                    });
                });
            }
        });
    }

    getUserInfo(): LoggedInUserInfo {
        var userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
            return JSON.parse(userInfo);
        }
        else
            return null;
    }
}

interface AuthenticateOptions {
    newPasswordHandler: Function
};



// old

// import { environment } from "../../environments/environment";
// import { Injectable } from "@angular/core";
// import { Router, ActivatedRoute } from "@angular/router";
// import { CognitoCallback, CognitoUtil, LoggedInCallback } from "./cognito.service";
// import { AuthenticationDetails, CognitoUser, CognitoUserPool, CookieStorage } from "amazon-cognito-identity-js";
// import * as AWS from "aws-sdk/global";
// import { ToasterService } from "angular2-toaster";
// import { Constants } from "./constants";
// import { CacheService } from "./cache.service";
// import { DataService } from "./data.service";
// import { SpinnerService } from "./spinner.service";
// // import { BsModalService } from "ngx-bootstrap";
// import { Observable } from "rxjs/Observable";
// @Injectable()
// export class UserLoginService {

//     public rememberMe;
//     public userName;
//     private cognitoUser: CognitoUser;
//     private cognitoHandlers;

//     constructor(
//         private activeRoute: ActivatedRoute,
//         private cognitoUtil: CognitoUtil,
//         private router: Router,
//         private toasterService: ToasterService,
//         private constants: Constants,
//         private cacheService: CacheService,
//         private dataService: DataService,
//         private spinner: SpinnerService,
//         // private modalService: BsModalService
//     ) {
//     }

//     authenticate(username: string, password: string) {
//         console.log("UserLoginService: starting the authentication")
//         self = this;
//         let authenticationData = {
//             Username: username,
//             Password: password,
//         };
//         let authenticationDetails = new AuthenticationDetails(authenticationData);
//         var poolData = {
//             UserPoolId: environment.userPoolId,
//             ClientId: environment.clientId
//         };
//         var userPool = new CognitoUserPool(poolData)


//         let userData = {
//             Username: username,
//             Pool: userPool
//         };

//         console.log("UserLoginService: Params set...Authenticating the user");
//         this.cognitoUser = new CognitoUser(userData);
//         console.log("UserLoginService: config is " + AWS.config);
//         var self = this;

//         //this.rememberMe = rememberMe;
//         this.userName = username;

//         this.cognitoHandlers = {

//             onSuccess: this.authenticateSuccess.bind(this),

//             onFailure: this.authenticateFailed.bind(this),

//             //newPasswordRequired: this.newPasswordRequired.bind(this, options.newPasswordHandler)
//         }

//         //call cognito authenticateUser function to authenticate user
//         this.cognitoUser.authenticateUser(authenticationDetails, this.cognitoHandlers);
//     }

//     // public newPasswordRequired(newPasswordHandler, userAttributes, requiredAttributes, ) {
//     //     newPasswordHandler().subscribe((newPassword) => {
//     //         delete userAttributes.email_verified;
//     //         delete userAttributes.phone_number_verified;
//     //         delete userAttributes["custom:userDynId"];
//     //         console.log("Got the change password callback.", newPassword);
//     //         this.cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this.cognitoHandlers);
//     //     });
//     //     console.log(userAttributes, requiredAttributes);
//     // }

//     public authenticateSuccess(result) {
//         this.setCognitoUserSessionTokens(result);
//         //this.setRememberMe(this.rememberMe);
//         this.setUserName(this.userName);
//         this.dataService.resetAuthHeader();
//         this.spinner.hide();
//         // this.modalService.hide(1);
//         return this.router.navigate(["/events-list"], { relativeTo: this.activeRoute });
//     }

//     public authenticateFailed(err) {
//         console.log(err);
//         this.toasterService.pop(this.constants.TOAST_TYPE_ERROR, "", err.message);
//         this.spinner.hide();
//     }

//     isAuthenticated() {
//         //check if the user exists
//         // let cognitoUser = this.getUserName();
//         let cognitoUser = this.cognitoUtil.getCurrentUser();
//         if (cognitoUser != null) {
//             localStorage.setItem("userName", cognitoUser.getUsername());
//             return true;
//         } else {
//             console.log("UserLoginService: can't retrieve the current user");
//             return false;
//         }
//     }

//     isValidSession() {
//         console.log("checking session validity");
//         var isAuthenticated = false;
//         let cognitoUser = this.cognitoUtil.getCurrentUser();

//         if (cognitoUser) {
//             cognitoUser.getSession((err, session) => {
//                 if (err) {
//                     console.log("could not fetch user session tokens");
//                 } else {
//                     this.setCognitoUserSessionTokens(session);
//                     this.dataService.resetAuthHeader();
//                     isAuthenticated = true;
//                     console.log("user session is valid for this page");
//                 }
//             });
//         }
//         return isAuthenticated;
//     }

//     setRememberMe(rememberMe) {
//         if (rememberMe) {
//             //set remember me value as "1" if selected
//             localStorage.setItem("Rem", "1");
//         } else {
//             //set remember me value as "null" if not selected
//             localStorage.setItem("Rem", null);
//         }
//     }

//     getRememberMe() {
//         return this.rememberMe = localStorage.getItem("Rem");
//     }

//     setUserName(userName) {
//         this.userName = userName;
//         // localStorage.setItem("userName", this.userName);
//         sessionStorage.setItem("userName", this.userName);
//     }

//     getUserName() {
//         return this.userName = localStorage.getItem("userName");
//     }

//     logout() {
//         console.log("UserLoginService: Logging out");
//         //call cognito function for signout 
//         this.cacheService.remove(this.constants.COGNITO_IDTOKEN);
//         this.cacheService.remove(this.constants.COGNITO_ACCESSTOKEN);
//         this.cacheService.remove(this.constants.COGNITO_REFRESHTOKEN);
//         //localStorage.clear();
//         sessionStorage.clear();
//        // this.dataService.resetAuthHeader();
//         this.router.navigate(["/login"]);

//         // if (this.cognitoUtil.getCurrentUser().signOut()) {
//         //     this.cognitoUtil.getCurrentUser().signOut();
//         // }

//     }

//     setCognitoUserSessionTokens(userSession) {
//         // console.log("result = ",userSession.getIdToken().jwtToken)
//         this.cacheService.put(this.constants.COGNITO_IDTOKEN, userSession.getIdToken().jwtToken);
//         this.cacheService.put(this.constants.COGNITO_ACCESSTOKEN, userSession.getAccessToken().jwtToken);
//         this.cacheService.put(this.constants.COGNITO_REFRESHTOKEN, userSession.getRefreshToken().token);

//         // localStorage.setItem(this.constants.COGNITO_IDTOKEN, userSession.getIdToken().jwtToken);
//         // localStorage.setItem(this.constants.COGNITO_ACCESSTOKEN, userSession.getAccessToken().jwtToken);
//         // localStorage.setItem(this.constants.COGNITO_REFRESHTOKEN, userSession.getRefreshToken().token);
//         // this.dataService.resetAuthHeader();

//         sessionStorage.setItem(this.constants.COGNITO_IDTOKEN, userSession.getIdToken().jwtToken);
//         sessionStorage.setItem(this.constants.COGNITO_ACCESSTOKEN, userSession.getAccessToken().jwtToken);
//         sessionStorage.setItem(this.constants.COGNITO_REFRESHTOKEN, userSession.getRefreshToken().token);
//         this.dataService.resetAuthHeader();
//     }

//     public setLocalStorageUserName(newToken) {
//         const localIdTokenName = this.localStorageIdToken();
//         localStorage.setItem(localIdTokenName, newToken)
//         //this.cacheService.put(this.constants.COGNITO_IDTOKEN, newToken);
//     }

//     public localStorageIdToken() {
//         let poolData = {
//             UserPoolId: environment.userPoolId, // Your user pool id here
//             ClientId: environment.clientId // Your client id here
//         };
//         let userPool = new CognitoUserPool(poolData);
//         let userName;
//         const localUserName = localStorage.getItem("userName")
//         if (localUserName !== "null") {
//             userName = localUserName;
//         } else {
//             userName = sessionStorage.getItem("userName");
//         }
//         const keyPrefix = `CognitoIdentityServiceProvider.${userPool.getClientId()}.${userName}.idToken`;
//         return keyPrefix;
//     }

//     public refreshToken(): any {
//         const poolData = {
//             UserPoolId: environment.userPoolId,
//             ClientId: environment.clientId
//         };
//         const userPool = new CognitoUserPool(poolData);
//         const cognitoUser = userPool.getCurrentUser();
//         /* =======================================
//         Code of the refreshSession is document on -
//         https://github.com/aws/aws-amplify/tree/master/packages/amazon-cognito-identity-js
//         Use case 32. Handling expiration of the Id Token.
//         ========================================*/
//         const oldToken = Observable.bindNodeCallback(cognitoUser.getSession).bind(cognitoUser);
//         const newToken = Observable.bindNodeCallback(cognitoUser.refreshSession).bind(cognitoUser);
//         return oldToken()
//             .flatMap(oldSession => {
//                 return newToken(oldSession.getRefreshToken());
//             }).flatMap(refreshToken => {
//                 return Observable.of(refreshToken.idToken.jwtToken);
//             }).catch(err => {
//                 return Observable.throw(err);
//             })
//     }

//     public forgotpassword(username, options?) {
//         var poolData = {
//             UserPoolId: environment.userPoolId, // Your user pool id here
//             ClientId: environment.clientId // Your client id here
//         };
//         var userPool = new CognitoUserPool(poolData)
//         var userData = {
//             Username: username,
//             Pool: userPool
//         };
//         var cognitoUser = new CognitoUser(userData);

//         cognitoUser.forgotPassword({
//             onSuccess: (data) => {
//                 console.log("CodeDeliveryData from forgotPassword: " + data);
//             },
//             onFailure: (err) => {
//                 this.toasterService.pop(this.constants.TOAST_TYPE_ERROR, "", err.message);
//             },
//             inputVerificationCode: (data) => {
//                 var ROUTERS = this.router;
//                 var TOASTER = this.toasterService;
//                 var CONSTANTS = this.constants;
//                 // var MODALSERVICE = this.modalService
//                 this.toasterService.pop(this.constants.TOAST_TYPE_SUCCESS, "", this.constants.OTP_SENT_SUCCESSFULLY);
//                 options().subscribe((newPassword) => {
//                     var otp = newPassword[0];
//                     var pwd = newPassword[1];
//                     cognitoUser.confirmPassword(otp, pwd, {
//                         onSuccess() {
//                             console.log("Password confirmed!");
//                             TOASTER.pop(CONSTANTS.TOAST_TYPE_SUCCESS, "", CONSTANTS.PASSWORD_RESET_SUCCESSFUL);
//                             // setTimeout(() => { MODALSERVICE.hide(1); }, 1000);
//                             setTimeout(() => { ROUTERS.navigate(["/login"]); }, 1000);
//                         },
//                         onFailure(err) {
//                             console.log("Password not confirmed!");
//                             TOASTER.pop(CONSTANTS.TOAST_TYPE_ERROR, "", err.message);
//                         }
//                     });
//                 });
//             }
//         });
//     }
// }



