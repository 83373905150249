import { RouterModule } from "@angular/router";
import { BookClassComponent } from "./book-class/book-class.component";
import { NgModule } from "@angular/core";

const routes=[
    {path:'schedule/:courseId/:coursePackage', component: BookClassComponent}
];
@NgModule({
    imports:[RouterModule.forChild(routes)],
    exports:[RouterModule]
})
export class ScheduleRoutingModule{}