import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomePageService } from '../../home-all/homepage/homepage.service';
import { FooterNavbarSharedService } from '../footer-navbar-shared/footer-navbar-shared.service';
import { StripTagsPipe } from '../../home-all/homepage/removeHtmlPipe';
import Swal from 'sweetalert2';
import { UntypedFormBuilder, UntypedFormGroup, FormArray, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-footer-redirect',
    templateUrl: './footer-redirect.component.html',
    styleUrls: ['./footer-redirect.component.scss']
})
export class FooterRedirectComponent implements OnInit {
    test : Date = new Date();
    homeData = [];
    footerData: any;
    activeLanguage;
    actLanguage: string;
    testCompleted: boolean = false;
    subscribeForm = new UntypedFormGroup({
    });
    constructor(private fb: UntypedFormBuilder, private router: Router, private homePageService: HomePageService, private fooService: FooterNavbarSharedService,
      private stripeTags: StripTagsPipe ) {
      this.subscribeForm = this.fb.group({
      emailId:['',Validators.required],
    });
      this.fooService.currentDataFetch.subscribe(res => {
          if(res){
            this.actLanguage = res;
            this.getHomepage();
          }
        })
    }

    ngOnInit() {
      this.getHomepage();
    }
    getPath(){
      return this.router.url;
    }

    routeToLink(url){
      var newUrl = this.stripeTags.transform(url)
      console.log(newUrl);
      if (newUrl.includes('https' || 'http')) {
        // window.location.href = newUrl
        window.open(newUrl, "_blank");
      } else{
        this.router.navigate([newUrl])
      }

    }
  
  subscribe(data){
  if (!(data.emailId)) {
        Swal.fire({
          icon: 'error',
          title: 'Please enter your Email Id.',
        })
        // alert("Please enter your Email Id.")
        return this.testCompleted = true;
    } 
  else {
    let rgex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validEmail = rgex.test(data.emailId);
    if (validEmail) { this.testCompleted = false; } 
    else {
        Swal.fire({
          icon: 'error',
          title: 'Please enter the correct Email Id',
        })
        // alert("Please enter the correct Email Id")
        return this.testCompleted = true;
    }
  }
    Swal.fire({ position: 'center', icon: 'success', title: 'Welcome aboard! Thanks for Subscribing.', timer: 2000,
    showConfirmButton: true, showClass: {popup: 'animate__animated animate__fadeInDown'}, 
    hideClass: { popup: 'animate__animated animate__fadeOutUp'}})
  }
    getHomepage() {
      let lang = sessionStorage.getItem('activeLanguage');
      if(sessionStorage.getItem('activeLanguage')){
        this.actLanguage = '';
        this.actLanguage = lang;
      } else{
        sessionStorage.setItem('activeLanguage', 'English');
        this.actLanguage = 'English'
      }
      let defaultLanguage;
      const payload = {
        getDataByLanguage: this.actLanguage
      };
      this.homePageService.getHomepage(payload).subscribe(
        (res) => {
          this.homeData = res.data;
          defaultLanguage = res.language;
          if(res.data != null){
            for (let i = 0; i < this.homeData.length; i++) {
              if (this.homeData[i].sectionName == "sectionFooter") {
                this.footerData = this.homeData[i][defaultLanguage].content;
                
              }
            }
          } else{
            this.footerData = null;
          }
         
        },
        (error) => {
          console.log(error);
        }
      );
    }
}
